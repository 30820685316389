import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from "@mdx-js/react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SSRProvider from 'react-bootstrap/SSRProvider'

import AdmissionsOverviewBottomMenu from '../../../../components/admissionsOverviewBottomMenu'
import DefaultHeader from '../../../../components/default-header'
import Footer from '../../../../components/footer'
import GdprPanel from "../../../../components/gdpr"
import Navigation from '../../../../components/navigation'
import OisFullRegistrationLoop from '../../../../components/forms/oisFullRegistrationLoop'
import PageQuote from "../../../../components/quote"
import Seo from '../../../../components/seo'
import SpeakWithAdmissions from "../../../../components/speakWithAdmissions"

import '../../../../styles/admissions-overview.scss'
import NewsletterSignup from '../../../../components/newsletterSignUp';
 
const Page = ({ data, location }) => {

    const shortcodes = {
        Container,
        Row,
        Col,
      }

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <DefaultHeader headline={data.mdx.frontmatter.headline} heroMobileBackground={data.mdx.frontmatter.heroMobileBackground} heroBackground={data.mdx.frontmatter.heroBackground} subHeadline={data.mdx.frontmatter.subheadline} type="rfi"  />
            <div id="body" class="rfi white-bg">

                <MDXProvider components={shortcodes}>
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </MDXProvider>   
                
                <div id="signUp">
                    <Container fluid="lg" className="py-4 py-xl-5">
                        <Row className="py-4 py-xl-5 justify-content-center">
                            <Col md={10} lg={6} className="mb-md-4 mb-lg-0 mb-0">
                                <AdmissionsOverviewBottomMenu />
                            </Col>
                            <Col md={10} lg={6}>
                                <SpeakWithAdmissions menuType="bottom-green" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage}
    />
)

export const query = graphql`
    query RFIPageQuery {
        mdx(fileAbsolutePath: {regex: "/request-for-information.mdx/"})  {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }    
`

export default Page